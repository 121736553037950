<template>
  <view-container breadcrumbs scroll-fab>
    <v-alert class="ma-5" type="info" v-if="!getOrders || getOrders.length === 0">
      No active orders
    </v-alert>
    
    <v-container v-for="(order, i) in getOrders" :key="i" fluid>
      <order-card :order="order" audiance="Customer" />
    </v-container>

    <!-- <v-card flat style="height: 100%; position: relative">
      <v-list two-line>
        <div v-for="(order, i) in getOrders" :key="i">
          <v-list-item
            link
            :class="i % 2 === 0 ? 'white' : 'grey lighten-5'"
            @click="
              () =>
                $$router.push({ name: 'order', params: { uuid: order.uuid } })
            "
          >
            <profile-logo
              :profile-name="order.store.name"
              :profile-uuid="order.store.uuid"
              path="stores/logos"
            />
            <v-list-item-content>
              <v-list-item-title>
                {{ order.timeString }} | {{ $t("order.toAddress") }}:
                {{ order && order.address ? order.address.title : "" }}
              </v-list-item-title>
              <v-list-item-subtitle>
                {{ formatItemsString(order.items) }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action class="px-3 pt-3">
              <v-chip-group>
                <v-chip
                  :color="order.statusColor"
                  dark
                  label
                  style="min-width: 140px"
                >
                  <div class="text-center">
                    {{ order.activeStatus }}
                  </div></v-chip
                >
              </v-chip-group>
            </v-list-item-action>
          </v-list-item>

          <v-stepper
            v-if="isActiveOrder(order.activeStatus)"
            style="box-shadow: none"
            flat
            :class="`ma-0 pa-0 ${i % 2 === 0 ? 'white' : 'grey lighten-5'}`"
          >
            <v-stepper-header>
              <v-stepper-step
                :complete="completedStep(order.activeStatus, 'WAITING')"
                step="1"
              >
                {{
                  completedStep(order.activeStatus, "PREPARING")
                    ? "Accepted"
                    : "Waiting"
                }}
              </v-stepper-step>
              <v-divider />
              <v-stepper-step
                :complete="completedStep(order.activeStatus, 'PREPARING')"
                step="2"
              >
                Preparing
              </v-stepper-step>
              <v-divider />
              <v-stepper-step
                :complete="completedStep(order.activeStatus, 'DISPATCHED')"
                step="3"
              >
                Out for Delivery
              </v-stepper-step>
              <v-divider />
              <v-stepper-step
                :complete="completedStep(order.activeStatus, 'DELIVERED')"
                step="4"
              >
                Delivered
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>

          <v-progress-linear
            :color="order.statusColor"
            buffer-value="0"
            stream
            v-if="isActiveOrder(order.activeStatus)"
            size="2"
          ></v-progress-linear>
          <v-divider />
        </div>
      </v-list>
    </v-card> -->
  </view-container>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";
import mixin from "../orders/orders.mixin";
import ViewContainer from "@/common/layouts/ViewContainer";
import ProfileLogo from "@/common/components/ProfileLogo";
import OrderCard from '@/views/orders/OrderCard.vue';

export default {
  name: "Orders",
  mixins: [mixin],
  components: { ViewContainer, OrderCard, ProfileLogo },
  mounted() {
    this.loadMyOrders().then(() => {
      console.log("My Orders", this.getOrders);
    });
  },
  computed: {
    ...mapGetters("orders", ["getMyOrders"]),
    ...mapGetters("stores", ["getOpenStore"]),

    getOrders() {
      const orders = this.getMyOrders;
      if (this.notEmptyArray(orders)) {
        const list = orders.map((o) => {
          const activeStatus = this.getActiveStatus(o.tracks);
          const statusColor = this.getStatusTypeColor(activeStatus);
          const timeString = this.formatTime(o.time);
          return { ...o, activeStatus, statusColor, timeString };
        });
        return _.sortBy(list, (o) => o.time).reverse();
      }
      return [];
    },
    getDefaultCurrency() {
      const cs = this.getOpenStore;
      return cs.currency;
    },
  },
  methods: {
    ...mapActions("orders", ["loadMyOrders"]),

    completedStep(current, status) {
      const statusValues = [
        ["WAITING"],
        ["PREPARING", "LOADING"],
        ["DISPATCHED", "DELIVERY", "NEAR"],
        ["DELIVERED"],
      ];
      return (
        statusValues.indexOf(statusValues.find((f) => f.includes(current))) >=
        statusValues.indexOf(statusValues.find((f) => f.includes(status)))
      );
    },
    formatTime(t) {
      return moment(t).format("ddd | DD/MM/YYYY hh:mm A");
    },
    formatItemsString(items) {
      if (!this.notEmptyArray(items)) {
        return "";
      }

      let result = [];
      for (let i in items) {
        if (items[i].product) {
          result.push(`${items[i].product.name} x ${items[i].quantity}`);
        }
      }
      return result.join(" | ");
    },
    getActiveStatus(tracks) {
      console.log("tracks", tracks);
      const track = tracks
        .sort((a, b) => new Date(a.time) - new Date(b.time))
        .pop();
      return !!track ? track.status : null;
    },

    getStoreLogo(uuid) {
      return `${this.getApiBaseUrl}/files/image?path=stores/logos/_${uuid}.png`;
    },
  },
};
</script>
